
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { AnimalTypeListModel } from "@/domain/animal/animalTypeList/model/AnimalTypeListModel";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const animalTypeList = ref<AnimalTypeListModel[]>([]);
    const originalAnimalTypeList = ref<AnimalTypeListModel[]>([]);

    const isLoading = ref<boolean>(false);

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const animalController = store.state.ControllersModule.animalController;

    watch(
      () => store.getters.newAnimalType,
      () => {
        if (store.getters.newAnimalType) getAnimalTypes(1);
      }
    );

    const getAnimalTypes = (page) => {
      isLoading.value = true;
      animalTypeList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalTypeListModel: AnimalTypeListModel = {
        animalType: {
          id: 0,
        },
        page: page,
        pageSize: pageSize.value,
      };

      animalController
        .animalTypeList(animalTypeListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalType) => {
              totalItems.value = animalType.pagination.total;
              currentPage.value = animalType.pagination.page;
              pageCount.value = animalType.pagination.pageCount;
              animalTypeList.value.push(animalType);
              originalAnimalTypeList.value.push(animalType);
            });
          } else {
            animalTypeList.value = [];
            originalAnimalTypeList.value = [];

            swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.BREED));
      getAnimalTypes(1);
    });

    return {
      PAGE_TITLE_PATH,
      isLoading,
      getAnimalTypes,
      animalTypeList,
      originalAnimalTypeList,
      totalItems,
      currentPage,
      pageCount,
      pageSize,
    };
  },
});
