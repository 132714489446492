<template>
  <div class="card">
    <div class="card-header border-0 pt-3">
      <h3 class="card-title align-items-start flex-column">
        <div class="card-title card-label fw-bolder text-dark">
          {{ $t(PAGE_TITLE_PATH.ANIMAL_TYPES) }}
        </div>
      </h3>
      <div class="card-toolbar">
        <!--
            <button
              type="button"
              class="btn btn-sm btn-light-primary me-3"
              data-bs-toggle="modal"
                          data-bs-target="#add_animalType_modal"
            >
              <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr078.svg" />
              </span>
              {{ $t("menuPages.animalType.button.addAnimalType") }}
            </button>
            -->
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div
          v-if="isLoading"
          style="
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div class="text-center px-5 pb-7">
            <img
              alt="Logo"
              class="max-h-35px"
              src="/media/loading_icon.gif"
              style="width: 40px"
            />
          </div>
        </div>
        <div class="table-responsive" v-else-if="animalTypeList.length != 0">
          <table
            class="
              table
              align-middle
              table-row-dashed
              fs-6
              gy-5
              dataTable
              no-footer
            "
            id="kt_customers_table"
            role="grid"
          >
            <!--begin::Table-->
            <thead>
              <tr
                class="
                  text-start text-gray-400
                  fw-bolder
                  fs-7
                  text-uppercase
                  gs-0
                "
                role="row"
              >
                <td>#</td>
                <td>{{ $t("menuPages.animalTypes.name") }}</td>
              </tr>
            </thead>

            <tbody class="fw-bold text-gray-600">
              <tr
                class="odd"
                v-for="(animalType, index) in animalTypeList"
                :key="animalType.animalType.id"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  <a>{{ animalType.animalType.name }}</a>
                </td>
              </tr>
            </tbody>
            <!--end::Table-->
          </table>
        </div>
        <div v-else class="card-px text-center">
          <div class="text-center px-5 pb-7">
            <img
              src="/media/animal/animal.jpg"
              class="symbol symbol-100px symbol-lg-160px mw-100 mh-150px"
            />
          </div>

          <!--begin::Description-->
          <p class="text-gray-400 fs-5 fw-bold">
            <span>{{ $t("menuPages.animalTypes.zeroCount") }}</span>
          </p>
          <!--end::Description-->
        </div>
      </div>

      <!--begin::Pagination-->
      <div
        class="d-flex flex-stack flex-wrap pt-10"
        v-if="originalAnimalTypeList.length != 0"
      >
        <!--begin::Controls-->
        <div class="d-flex flex-wrap my-1">
          <!--begin::Select wrapper-->
          <div class="m-0">
            <!--begin::Select-->
            <select
              name="status"
              data-control="select2"
              data-hide-search="true"
              class="
                form-select form-select-white form-select-sm
                fw-bolder
                w-125px
              "
              v-model="pageSize"
              @change="getAnimalTypes(1)"
            >
              <option value="5">5</option>
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
            <span>{{ $t("common.table.pagination.desc2") }}</span>

            <!--end::Select-->
          </div>
          <!--end::Select wrapper-->
        </div>
        <!--end::Controls-->

        <div class="fs-6 fw-bold text-gray-700">
          {{ totalItems }} {{ $t("common.table.pagination.desc") }}
          {{ (currentPage - 1) * pageSize + 1 }} -
          {{ Math.min(currentPage * pageSize, totalItems) }}
          {{ $t("common.table.pagination.desc1") }}
        </div>

        <!--begin::Pages-->
        <ul class="pagination">
          <li
            class="page-item previous"
            @click="getAnimalTypes(currentPage - 1)"
            :class="{ disabled: currentPage === 1 }"
          >
            <a href="#" class="page-link"><i class="previous"></i></a>
          </li>

          <li
            v-for="page in pageCount"
            :key="page"
            class="page-item"
            :class="{ active: currentPage === page }"
          >
            <a href="#" class="page-link" @click="getAnimalTypes(page)">{{
              page
            }}</a>
          </li>

          <li
            class="page-item next"
            @click="getAnimalTypes(currentPage + 1)"
            :class="{ disabled: currentPage === pageCount }"
          >
            <a href="#" class="page-link"><i class="next"></i></a>
          </li>
        </ul>
        <!--end::Pages-->
      </div>
      <!--end::Pagination-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { AnimalTypeListModel } from "@/domain/animal/animalTypeList/model/AnimalTypeListModel";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const animalTypeList = ref<AnimalTypeListModel[]>([]);
    const originalAnimalTypeList = ref<AnimalTypeListModel[]>([]);

    const isLoading = ref<boolean>(false);

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const animalController = store.state.ControllersModule.animalController;

    watch(
      () => store.getters.newAnimalType,
      () => {
        if (store.getters.newAnimalType) getAnimalTypes(1);
      }
    );

    const getAnimalTypes = (page) => {
      isLoading.value = true;
      animalTypeList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalTypeListModel: AnimalTypeListModel = {
        animalType: {
          id: 0,
        },
        page: page,
        pageSize: pageSize.value,
      };

      animalController
        .animalTypeList(animalTypeListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalType) => {
              totalItems.value = animalType.pagination.total;
              currentPage.value = animalType.pagination.page;
              pageCount.value = animalType.pagination.pageCount;
              animalTypeList.value.push(animalType);
              originalAnimalTypeList.value.push(animalType);
            });
          } else {
            animalTypeList.value = [];
            originalAnimalTypeList.value = [];

            swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.BREED));
      getAnimalTypes(1);
    });

    return {
      PAGE_TITLE_PATH,
      isLoading,
      getAnimalTypes,
      animalTypeList,
      originalAnimalTypeList,
      totalItems,
      currentPage,
      pageCount,
      pageSize,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
